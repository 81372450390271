import React, { useState } from 'react';
import './MiniPortfolioSec.scss';
import img1 from '../../assets/portfolio1.avif';
import img2 from '../../assets/portfolio2.webp';
import img3 from '../../assets/portfolio3.webp';
import { Button } from '@mui/material';

const MiniPortfolioSec = ({ scrollToDiv, setshowSideBar }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const offsetX = e.clientX - centerX;
    const offsetY = e.clientY - centerY;

    setPosition({ x: (offsetX / 10) * -1, y: (offsetY / 10) * -1 });
  };

  return (
    <div className="mini-portfolio-section" onMouseMove={handleMouseMove}>
      <div className="container">
        <div className="wrapper">
          <div className="left">
            <p className="title">Transforming Ideas into Digital Solutions</p>
            <p className="text">
              Looking for a complete solution for full-stack web development, backend systems, deployment, hosting, or
              domain transfers? You're in the right place! I offer a modern, streamlined approach to web development,
              ensuring high-quality, scalable solutions tailored to your needs. Let's build something amazing together.
            </p>
            <div className="bttn-wrapper">
              <Button className="demo-bttn" onClick={scrollToDiv}>
                View Demo
              </Button>
              <Button className="consultation-bttn" onClick={() => setshowSideBar(true)}>
                Free Consultation
              </Button>
            </div>
          </div>
          <div className="right">
            <img
              src={img3}
              alt="img1"
              style={{
                transform: `translate(${position.x * 1.2}px, calc(20% + ${position.y * 1.2}px))`,
              }}
            />
            <img
              src={img2}
              alt="img2"
              style={{
                transform: `translate(${position.x * 1.1}px, calc(10% + ${position.y * 1.1}px))`,
              }}
            />
            <img
              src={img1}
              alt="img3"
              style={{
                transform: `translate(${position.x * 1.3}px,  ${position.y * 1.3}px)`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniPortfolioSec;
